import React, { Component } from 'react'

export default class Dividers extends Component {
  renderDefault() {
    return (
      <div className="gdlr-core-pbf-element">
        <div className="gdlr-core-divider-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-divider-item-normal">
          <div className="gdlr-core-divider-line gdlr-core-skin-divider" />
        </div>
      </div>
    )
  }
  render() {
    return this.renderDefault()
  }
}
