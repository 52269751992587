import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import CasesBlock from '../components/Cases/CasesBlock'
import Hero from '../components/Hero'
export default class CaseStudiesPage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className="gdlr-core-page-builder-body">
          <Hero title="Our cases" description="What we have done" />
          <CasesBlock renderCaseList />
        </div>
      </Layout>
    )
  }
}
