import React, { Component } from 'react'
import { Link } from 'gatsby'
import CaseCard from './CaseCard'
import Divider from '../Common/Dividers'
export default class OurCases extends Component {
  render() {
    const image1 = require('../../assets/images/rawpixel-252130-unsplash.jpg')
    const image2 = require('../../assets/images/rawpixel-701117-unsplash.jpg')
    const image3 = require('../../assets/images/rawpixel-574844-unsplash.jpg')
    return (
      <div>
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-element">
              <div
                className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                style={{ paddingBottom: 60 }}
              >
                <div className="gdlr-core-title-item-title-wrap ">
                  <h3
                    className="gdlr-core-title-item-title gdlr-core-skin-title "
                    style={{
                      fontSize: 45,
                      fontWeight: 600,
                      letterSpacing: 0,
                      textTransform: 'none',
                      color: '#232323',
                    }}
                  >
                    Our Cases<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                  </h3>
                </div>
                <span
                  className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                  style={{
                    fontSize: 14,
                    fontStyle: 'normal',
                    letterSpacing: 2,
                    textTransform: 'uppercase',
                    marginTop: 5,
                  }}
                >
                  What we have done
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="gdlr-core-pbf-section">
          <div className="gdlr-core-pbf-section-container gdlr-core-container clearfix">
            <div className="gdlr-core-pbf-element">
              <div
                className="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix  gdlr-core-portfolio-item-style-grid"
                style={{ paddingBottom: 10 }}
              >
                <div
                  className="filter-container gdlr-core-portfolio-item-holder gdlr-core-js-2 clearfix"
                  data-layout="fitrows"
                >
                  <CaseCard 
                    title='Business development'
                    description='4X increase in non-local customer acquisition through new market discovery and key account management'
                    image={image1}

                  />
                  <CaseCard 
                    title='Training & Advisory'
                    description='Our client, a global development agency focusing on funding young entrepreneurs was struggling with structuring a programme that targets end-to-end due diligence, selection and support of the early stage businesses'
                    image={image2}

                  />
                  <CaseCard 
                    title='Product management'
                    description='Our client, a tech start-up was facing difficultly in gathering traction for their product. The main challenges were poor business model, unclear customer acquisition plan and a loose product-market fit.'
                    image={image3}

                  />
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-element">
              <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                <Link
                  className="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border"
                  to="/case-studies"
                  style={{
                    fontSize: 15,
                    color: '#ffffff',
                    padding: '17px 38px 23px 38px',
                    textTransform: 'none',
                    borderRadius: 33,
                    MozBorderRadius: 33,
                    WebkitBorderRadius: 33,
                    background: '#2d5fde',
                  }}
                >
                  <span className="gdlr-core-content">View All Cases</span>
                </Link>
              </div>
            </div>
            <Divider />
          </div>
        </div>
      </div>
    )
  }
}
