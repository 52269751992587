import React, { Component } from 'react'
import OurSuccessStories from './OurSuccessStories'
import OurCases from './OurCases'
import RequestCallback from '../RequestCallback/RequestCallback'
import ShareThisBlockAlternate from '../Social/ShareThisBlockAlternate'
import LeftCaseCard from './LeftCaseCard'
export default class CasesBlock extends Component {
  renderListCases() {
    const image1 = require('../../assets/images/rawpixel-252130-unsplash.jpg')
    const image2 = require('../../assets/images/rawpixel-701117-unsplash.jpg')
    const image3 = require('../../assets/images/rawpixel-574844-unsplash.jpg')
    const image4 = require('../../assets/images/maarten-van-den-heuvel-73123-unsplash.jpg')
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '80px 0px 30px 0px' }}
      >
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div
            className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container-custom"
            style={{ maxWidth: 1000 }}
          >
            <div className="gdlr-core-pbf-element">
              <div
                className="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix  gdlr-core-portfolio-item-style-medium"
                style={{ paddingBottom: 40 }}
              >
                <div
                  className="gdlr-core-portfolio-item-holder gdlr-core-js-2 clearfix"
                  data-layout="fitrows"
                >
                  <LeftCaseCard
                    title={`Business Development`}
                    description="4X increase in non-local customer acquisition through new market discovery and key account management"
                    tag="Business"
                    tag2="Development"
                    image={image1}
                    linkTo="business-development-case-study"
                  />
                  <LeftCaseCard
                    title={`Training & Advisory`}
                    description="Our client, a global development agency focusing on funding young entrepreneurs was struggling with structuring a programme that targets end-to-end due diligence, selection and support of the early stage businesses."
                    tag="Training"
                    tag2="Advisory"
                    image={image2}
                    linkTo="training-advisory-case-study"
                  />
                  <LeftCaseCard
                    title={`Product Management`}
                    description="Our client, a tech start-up was facing difficultly in gathering traction for their product. The main challenges were poor business model, unclear customer acquisition plan and a loose product-market fit."
                    tag="Customer development"
                    tag2="Product-market fit"
                    image={image3}
                    linkTo="product-management-case-study"
                  />
                  <LeftCaseCard
                    title={`Market Research`}
                    description="Our client, a clothing manufacturing company in East Africa had identified a viable business opportunity within textile sector in East African region. "
                    tag="Market Research"
                    tag2="Reporting"
                    image={image4}
                    linkTo="market-research-case-study"
                  />
                </div>
              </div>
            </div>
          </div>
          <RequestCallback renderDark />
          <ShareThisBlockAlternate />
        </div>
      </div>
    )
  }
  render() {
    if (this.props.renderCaseList) {
      return this.renderListCases()
    }
    return (
      <div>
        <OurSuccessStories />
        <OurCases />
      </div>
    )
  }
}
