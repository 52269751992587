import React, { Component } from 'react'

export default class ShareThisBlockAlternate extends Component {
  render() {
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '80px 0px 50px 0px' }}
      >
        <div
          className="gdlr-core-pbf-background-wrap"
          style={{ backgroundColor: '#ffffff' }}
        />
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-element">
              <div className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-item-pdlr">
                <a
                  href="mailto:info@boragrowth.com"
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="email"
                  style={{ fontSize: 20, color: '#1e1e1e' }}
                >
                  <i className="fa fa-envelope" />
                </a>
                <a
                  href="https://www.linkedin.com/company/bora-growth-partners/"
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="linkedin"
                  style={{
                    fontSize: 20,
                    color: '#1e1e1e',
                    marginLeft: 40,
                  }}
                >
                  <i className="fa fa-linkedin" />
                </a>
                <a
                  href="https://twitter.com/BoraGrowth"
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="twitter"
                  style={{
                    fontSize: 20,
                    color: '#1e1e1e',
                    marginLeft: 40,
                  }}
                >
                  <i className="fa fa-twitter" />
                </a>
                <a
                  href="https://facebook.com/BoraGrowth"
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="facebook"
                  style={{
                    fontSize: 20,
                    color: '#1e1e1e',
                    marginLeft: 40,
                  }}
                >
                  <i className="fa fa-facebook" />
                </a>
                <a
                  href="https://instagram.com/BoraGrowth"
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="instagram"
                  style={{
                    fontSize: 20,
                    color: '#1e1e1e',
                    marginLeft: 40,
                  }}
                >
                  <i className="fa fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
