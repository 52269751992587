import React, { Component } from 'react'
import ContactForm from '../Common/ContactForm'
export default class RequestCallback extends Component {
  renderBlock() {
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '90px 0px 60px 0px' }}
        data-skin="Contact Form White"
      >
        <div
          className="gdlr-core-pbf-background-wrap"
          style={this.props.renderDark ? { backgroundColor: '#f4f4f4' } : null}
        />
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                data-sync-height="height-contact"
                data-sync-height-center
              >
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: 20 }}
                    >
                      <div className="gdlr-core-title-item-title-wrap ">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title "
                          style={{
                            fontSize: 40,
                            fontWeight: 600,
                            letterSpacing: 0,
                            textTransform: 'none',
                            color: '#2d5fde',
                          }}
                        >
                          Request a call back?<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-divider-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-divider-item-normal">
                      <div
                        className="gdlr-core-divider-container gdlr-core-center-align"
                        style={{ maxWidth: 60 }}
                      >
                        <div
                          className="gdlr-core-divider-line gdlr-core-skin-divider"
                          style={{
                            borderColor: '#cccccc',
                            borderBottomWidth: 3,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{ fontSize: 18 }}
                      >
                        <p>
                          Please fill out the form and press the submit button.
                          <br /> We will get back to you with 1-2 business days.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-30">
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                data-sync-height="height-contact"
              >
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-contact-form-7-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                      <div
                        role="form"
                        className="wpcf7"
                        id="wpcf7-f5503-p5539-o1"
                        lang="en-US"
                        dir="ltr"
                      >
                        <div className="screen-reader-response" />
                        <ContactForm
                          centreButton
                          location={this.props.location}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.renderBlock()
  }
}
