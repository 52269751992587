import React, { Component } from 'react'

export default class OurSuccessStories extends Component {
  render() {
    const backgroundImage = require('../../assets/images/about-title-bg-stats.png')
    return (
      <div>
        <div
          className="gdlr-core-pbf-wrapper "
          style={{ padding: '100px 0px 120px 0px' }}
          id="gdlr-core-wrapper-1"
        >
          <div
            className="gdlr-core-pbf-background-wrap"
            style={{ backgroundColor: '#f0f0f0' }}
          >
            <div
              className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center',
              }}
              data-parallax-speed="0.1"
            />
          </div>
          <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
              <div className="gdlr-core-pbf-element">
                <div
                  className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                  style={{ paddingBottom: 67 }}
                >
                  <div className="gdlr-core-title-item-title-wrap ">
                    <h3
                      className="gdlr-core-title-item-title gdlr-core-skin-title "
                      style={{
                        fontSize: 43,
                        fontWeight: 600,
                        letterSpacing: 0,
                        textTransform: 'none',
                      }}
                    >
                      Our Success Stories<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                    </h3>
                  </div>
                  <span
                    className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                    style={{
                      fontSize: 14,
                      fontStyle: 'normal',
                      letterSpacing: 2,
                      textTransform: 'uppercase',
                      marginTop: 5,
                    }}
                  >
                    what we have done
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="gdlr-core-pbf-wrapper "
          style={{
            margin: '-120px auto 0px auto',
            padding: '85px 0px 45px 0px',
            maxWidth: 1280,
          }}
          data-skin="Hp3 Stats"
          id="gdlr-core-wrapper-2"
        >
          <div
            className="gdlr-core-pbf-background-wrap"
            style={{
              boxShadow: '0 0 74px rgba(20, 20, 20,0.07)',
              MozBoxShadow: '0 0 74px rgba(20, 20, 20,0.07)',
              WebkitBoxShadow: '0 0 74px rgba(20, 20, 20,0.07)',
              backgroundColor: '#ffffff',
            }}
          />
          <div
            className="gdlr-core-pbf-wrapper-content gdlr-core-js "
            // data-gdlr-animation="fadeInUp"
            // data-gdlr-animation-duration="600ms"
            // data-gdlr-animation-offset="0.8"
          >
            <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
              <div className="gdlr-core-pbf-column gdlr-core-column-15 gdlr-core-column-first">
                <div
                  className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                  style={{ padding: '0px 0px 0px 15px' }}
                >
                  <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                    <div className="gdlr-core-pbf-element">
                      <div className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                        <div
                          className="gdlr-core-counter-item-number gdlr-core-skin-title"
                          style={{ fontSize: 40 }}
                        >
                          <span
                            className="gdlr-core-counter-item-count gdlr-core-js"
                            // data-duration={2000}
                            // data-counter-start={0}
                            // data-counter-end={10000}
                          >
                            25
                          </span>
                        </div>
                        <div
                          className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content"
                          style={{ fontSize: 18, textTransform: 'none' }}
                        >
                          All Time Clients
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gdlr-core-pbf-column gdlr-core-column-15">
                <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                  <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                    <div className="gdlr-core-pbf-element">
                      <div className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                        <div
                          className="gdlr-core-counter-item-number gdlr-core-skin-title"
                          style={{ fontSize: 40 }}
                        >
                          <span
                            className="gdlr-core-counter-item-count gdlr-core-js"
                            // data-duration={2000}
                            // data-counter-start={0}
                            // data-counter-end={979}
                          >
                            9
                          </span>
                        </div>
                        <div
                          className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content"
                          style={{ fontSize: 18, textTransform: 'none' }}
                        >
                          Clients In This Year
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gdlr-core-pbf-column gdlr-core-column-15">
                <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                  <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                    <div className="gdlr-core-pbf-element">
                      <div className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                        <div
                          className="gdlr-core-counter-item-number gdlr-core-skin-title"
                          style={{ fontSize: 40 }}
                        >
                          <span
                            className="gdlr-core-counter-item-count gdlr-core-js"
                            // data-duration={2000}
                            // data-counter-start={0}
                            // data-counter-end={300}
                          >
                            5
                          </span>
                        </div>
                        <div
                          className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content"
                          style={{ fontSize: 18, textTransform: 'none' }}
                        >
                          New Projects
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gdlr-core-pbf-column gdlr-core-column-15">
                <div
                  className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                  style={{ padding: '0px 15px 0px 0px' }}
                >
                  <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                    <div className="gdlr-core-pbf-element">
                      <div className="gdlr-core-counter-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                        <div
                          className="gdlr-core-counter-item-number gdlr-core-skin-title"
                          style={{ fontSize: 40 }}
                        >
                          <span
                            className="gdlr-core-counter-item-count gdlr-core-js"
                            // data-duration={2000}
                            // data-counter-start={0}
                            // data-counter-end={35}
                          >
                            10
                          </span>
                          <span className="gdlr-core-counter-item-suffix">
                            %
                          </span>
                        </div>
                        <div
                          className="gdlr-core-counter-item-bottom-text gdlr-core-skin-content"
                          style={{ fontSize: 18, textTransform: 'none' }}
                        >
                          Avg Profit Increased
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="gdlr-core-pbf-wrapper "
          style={{ margin: '10px 0px 0px 0px', padding: '80px 0px 0px 0px' }}
        >
          <div className="gdlr-core-pbf-background-wrap">
            <div
              className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
              // data-parallax-speed={0}
            />
          </div>
        </div>
      </div>
    )
  }
}
