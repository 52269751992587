import React, { Component } from 'react'
import { Link } from 'gatsby'
export default class CaseCard extends Component {
  render() {
  
    return (
      <div className="gdlr-core-item-list  gdlr-core-item-pdlr class2 gdlr-core-column-20">
        <div className="gdlr-core-portfolio-grid  gdlr-core-left-align gdlr-core-style-normal">
          <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-icon">
            <div className="gdlr-core-portfolio-thumbnail-image-wrap  gdlr-core-zoom-on-hover">
              <img src={this.props.image} width={700} height={450} />
            </div>
          </div>
          <div className="gdlr-core-portfolio-content-wrap gdlr-core-skin-divider">
            <h3
              className="gdlr-core-portfolio-title gdlr-core-skin-title"
              style={{
                fontSize: 16,
                fontWeight: 500,
                letterSpacing: 0,
                textTransform: 'none',
              }}
            >
              <p>{this.props.title}</p>
            </h3>
            <div className="gdlr-core-portfolio-content">
              {this.props.description}
            </div>
            <div className="gdlr-core-portfolio-read-more-wrap">
              <Link
                className="gdlr-core-portfolio-read-more"
                to="/case-studies"
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
