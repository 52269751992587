import React, { Component } from 'react'
import { Link } from 'gatsby'
export default class LeftCaseCard extends Component {
  render() {
    return (
      <div className="gdlr-core-item-list gdlr-core-portfolio-medium  gdlr-core-size-large gdlr-core-style-left gdlr-core-item-pdlr">
        <div className="gdlr-core-portfolio-thumbnail-wrap">
          <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image  gdlr-core-style-title">
            <div className="gdlr-core-portfolio-thumbnail-image-wrap ">
              <img src={this.props.image} width={1800} height={1200} />
              <span className="gdlr-core-image-overlay  gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                <span className="gdlr-core-image-overlay-content">
                  <span className="gdlr-core-portfolio-title gdlr-core-title-font">
                    <Link to={this.props.linkTo}>{this.props.title}</Link>
                  </span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="gdlr-core-portfolio-content-wrap">
          <h3
            className="gdlr-core-portfolio-title gdlr-core-skin-title"
            style={{
              fontSize: 19,
              fontWeight: 700,
              letterSpacing: 2,
            }}
          >
            <Link to={this.props.linkTo}>{this.props.title}</Link>
          </h3>
          <span className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font gdlr-core-skin-caption">
            <Link to={this.props.linkTo} rel="tag">
              {this.props.tag}
            </Link>{' '}
            <span className="gdlr-core-sep">/</span>{' '}
            <Link to={this.props.linkTo} rel="tag">
              {this.props.tag2}
            </Link>
          </span>
          <div className="gdlr-core-portfolio-content">
            {this.props.description}
          </div>
        </div>
      </div>
    )
  }
}
